import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from '../utils/config';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
	retry?: number; // Total number of retries
	retryCount?: number; // Current umber of retries
	exponentialBackoffBase?: number; // Base for exponential backoff in ms
}

const DEFAULT_RETRY_PARAMS = {
	retry: 5,
	retryCount: 0,
	exponentialBackoffBase: 2000,
};

const retryRequest = (error: AxiosError) => {
	const config: ExtendedAxiosRequestConfig = error.config ?? {};
	if (!config || !config.retry) return Promise.reject(error);

	config.retryCount = config.retryCount || 0;

	if (config.retryCount >= config.retry) {
		return Promise.reject(error);
	}

	// Start with no delay, then exponential with base exponentialBackoffBase
	const initialDelayInSeconds = (config.exponentialBackoffBase || 2000) / 1000;
	const backoffTime = config.retryCount === 0 ? 0 : initialDelayInSeconds ** (config.retryCount - 1) * 1000;
	config.retryCount++;
	return new Promise<AxiosResponse>((resolve) => {
		setTimeout(() => resolve(axios.request(config)), backoffTime);
	});
};

axios.interceptors.response.use(undefined, (error: AxiosError) => {
	const { status } = error.response || { status: undefined };
	if (status === 502) {
		// Only retry requests that return 502
		return retryRequest(error);
	}
	return Promise.reject(error);
});

export class APIRequest {
	static async post(urlFragment: string, data: any): Promise<AxiosResponse<any>> {
		let url = `${Config.API_URL}${urlFragment}`;

		const res = axios.request({
			...DEFAULT_RETRY_PARAMS,
			url: url,
			method: 'POST',
			withCredentials: false,
			data,
		});

		return res;
	}

	static async patch(urlFragment: string, id: number, data: any): Promise<AxiosResponse<any>> {
		const url = `${Config.API_URL}${urlFragment}?id=${id}`;

		const res = axios.request({
			...DEFAULT_RETRY_PARAMS,
			url: url,
			timeout: 2000,
			method: 'PATCH',
			withCredentials: false,
			data,
		});

		return res;
	}

	static async delete(urlFragment: string, data: any): Promise<AxiosResponse<any>> {
		const url = `${Config.API_URL}${urlFragment}?id=${data.id}`;
		const res = axios.request({
			...DEFAULT_RETRY_PARAMS,
			url: url,
			method: 'DELETE',
			withCredentials: false,
		});

		return res;
	}

	static async get(urlFragment: string, pageSizes: number | undefined = undefined) {
		// TODO: revisar paginación en requests
		let url = `${Config.API_URL}${urlFragment}`;
		url = pageSizes ? `${url}?pageSize=${pageSizes}` : `${url}`;

		const res = axios.request({
			...DEFAULT_RETRY_PARAMS,
			url: url,
			method: 'GET',
			withCredentials: false,
		});

		return res;
	}

	static async getById(urlFragment: string, id: number) {
		let url = `${Config.API_URL}${urlFragment}?id=${id}`;
		const res = axios.request({
			...DEFAULT_RETRY_PARAMS,
			url: url,
			method: 'GET',
			withCredentials: false,
		});

		return res;
	}
}
