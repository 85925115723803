import { IonContent, IonSlide, IonSlides } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../assets/tutorial-1.jpg';
import img2 from '../assets/tutorial-2.jpg';
import img3 from '../assets/tutorial-3.jpg';
import { TutorialSlide } from '../components/TutorialSlide';
import { IonFullscreenPage } from '../helpers/Fullscreen';

const TutorialPage: React.FC<React.PropsWithChildren<unknown>> = () => {
	const { t } = useTranslation();
	return (
		<IonFullscreenPage>
			<IonContent fullscreen>
				<IonSlides pager={true}>
					<IonSlide>
						<TutorialSlide
							image={img1}
							title={t('tutorial.welcome.title')}
							content={t('tutorial.welcome.content')}
						/>
					</IonSlide>
					<IonSlide>
						<TutorialSlide
							image={img2}
							title={t('tutorial.scribbling.title')}
							content={t('tutorial.scribbling.content')}
						/>
					</IonSlide>
					<IonSlide>
						<TutorialSlide
							image={img3}
							title={t('tutorial.profiles.title')}
							content={t('tutorial.profiles.content')}
							isLastSlide={true}
						/>
					</IonSlide>
				</IonSlides>
			</IonContent>
		</IonFullscreenPage>
	);
};

export default TutorialPage;
