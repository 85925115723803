import { IonPage, IonRow } from '@ionic/react';
import React from 'react';
import logo from '../assets/logo.png';

const SplashPage: React.FC<React.PropsWithChildren<unknown>> = () => {
	return (
		<IonPage>
			<IonRow className="img-max-width splash-img">
				<img src={logo} alt="index" />
			</IonRow>
		</IonPage>
	);
};

export default SplashPage;
