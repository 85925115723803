import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './i18n/en.yaml';
import es from './i18n/es.yaml';

console.log('en:', en);
console.log('es:', es);

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: { en, es },
		fallbackLng: 'es',

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
