import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BookAcquisitions } from '../components/BookAcquisitions';
import { BookCover } from '../components/BookCover';
import { HeaderApp } from '../components/HeaderApp';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IDeepAuthorizationModel } from '../models/IAuthorizationModel';
import { IBookModel } from '../models/IBookModel';
import { Config } from '../utils/config';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const BookDetailPage: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
	}>
> = ({ middleware }) => {
	const bookId = +useParams<{ bookId: string }>().bookId;
	const history = useHistory();
	const params: any = history.location.state;
	const categoryId: number = params?.categoryId;
	const returnToStorePage: boolean = params?.returnToStorePage;
	const [book, setBook] = useState<IBookModel | null>(null);

	useEffect(() => {
		const subs = [middleware.books.byId$(bookId).subscribe(setBook)];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [bookId, middleware]);

	const handleOnOpen = (auth: IDeepAuthorizationModel | null) => {
		history.replace({
			pathname: `/books/read/${auth ? auth.id : 0}/${bookId}`,
			state: { direction: 'none' },
		});
	};

	const onBack = () => {
		let url = '/menu/library';
		console.log('HV ~ onBack ~ returnToStorePage', returnToStorePage);
		if (returnToStorePage) url = '/menu/store';
		history.replace({
			pathname: url,
			state: {
				direction: 'none',
				categoryId,
			},
		});
	};

	return (
		<IonPage>
			{/* TODO: we should go back to where we come from, which is independant from the acqs we can have*/}
			<HeaderApp onBack={onBack} />
			<IonContent fullscreen>
				{book && (
					<IonGrid>
						<IonRow className="ion-justify-content-center ion-no-padding">
							<IonCol sizeXs="8" sizeSm="5" sizeMd="5" sizeLg="3" sizeXl="2.5" className="ion-no-padding">
								<div className="pd-book-cover-shadow">
									<BookCover book={book} middleware={middleware} />
								</div>
							</IonCol>
							<IonCol sizeXs="12" sizeSm="10" sizeMd="10" sizeLg="7" sizeXl="6">
								<IonRow className="ion-justify-content-center">
									<IonCol size="12">
										<div className="pd-main-page-title ion-padding-horizontal">
											{Config.UI_DEBUG_ENTITY_IDS && `[#${book.id}]`}
											<span id="book-title">{book.title}</span>
										</div>
										<div className={'pd-book-detail-subtitle ion-text-left ion-padding'}>
											{book.subtitle}
										</div>
										<div
											className={'ion-text-left ion-padding'}
											dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(book.summary),
											}}
										/>
									</IonCol>
								</IonRow>

								<IonRow className="ion-justify-content-center">
									<IonCol size="12">
										<BookAcquisitions book={book} middleware={middleware} onOpen={handleOnOpen} />
									</IonCol>
								</IonRow>
							</IonCol>
						</IonRow>
					</IonGrid>
				)}
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('BookDetailPage', 'component')(BookDetailPage);
